@font-face {
  font-family: floob;
  src: url(./fonts/lacquer.woff);
}
h1, h5 {
  font-family: "floob";
}

.stretch-v {
  height: 100%;
}

.sidebar {
  height: 100%;
}

.content-area {
  height: 100%;
}

.series-circle {
  position: relative;
}

.series-circle__circle {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  position: relative;
  border-width: 0.2em;
  border-style: solid;
  z-index: 1;
  box-shadow: inset 0 0 0 #bcceda, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: white;
  text-shadow: 2px 2px 0 #abc3d1;
  background: linear-gradient(to bottom right, #edf2f5 50%, #dde6ec 50%);
}
.series-circle__circle.series-circle__circle--gold {
  box-shadow: inset 0 0 0 #b67d05, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #fadd40;
  text-shadow: 0 0 4px #9d6c04;
  background: linear-gradient(to bottom right, #f9ad0e 50%, #e89f06 50%);
}
.series-circle__circle.series-circle__circle--silver {
  box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #edeff1;
  text-shadow: 0px 0px 4px #98a6ad;
  background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}
.series-circle__circle.series-circle__circle--bronze {
  box-shadow: inset 0 0 0 #955405, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #f7bb23;
  text-shadow: 0 0 4px #7d4604;
  background: linear-gradient(to bottom right, #df7e08 50%, #c67007 50%);
}

.star {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  animation: glitter 4.5s linear 0s infinite normal;
  -webkit-animation: glitter 4.5s linear 0s infinite normal;
  -moz-animation: glitter 4.5s linear 0s infinite normal;
  -ms-animation: glitter 4.5s linear 0s infinite normal;
  -o-animation: glitter 4.5s linear 0s infinite normal;
}

/*CSS3 keyframes for glittering effect*/
@-webkit-keyframes glitter {
  0% {
    -webkit-transform: scale(0.2);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(0.2);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
}
@-moz-keyframes glitter {
  0% {
    -moz-transform: scale(0.5);
    opacity: 1;
  }
  25% {
    -moz-transform: scale(0.2);
    opacity: 0;
  }
  50% {
    -moz-transform: scale(0.5);
    opacity: 1;
  }
  75% {
    -moz-transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -moz-transform: scale(0.5);
    opacity: 1;
  }
}
@keyframes glitter {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  25% {
    transform: scale(0.2);
    opacity: 0;
  }
  50% {
    transform: scale(0.5);
    opacity: 1;
  }
  75% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(0.5);
    opacity: 1;
  }
}