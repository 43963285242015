@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@font-face {
    font-family: floob;
    src: url(./fonts/lacquer.woff);
}

h1, h5 {
    font-family: 'floob';
}


  
  $gold-medal: #f9ad0e;
  $silver-medal: #d1d7da;
  $bronze-medal: #df7e08;
  $neutral-medal: #edf2f5;
  
  .stretch-v{
    height:100%
  }

  .sidebar{
    height:100%;
  }

  .content-area{
    height:100%;
  }

  .series-circle {
    position: relative;
  }
  
  .series-circle__circle {
    width: 56px;
    height: 56px;
    border-radius: 100%;
    position: relative;
    border-width: 0.2em;
    border-style: solid;
    z-index: 1;
  
    // Default colors
    box-shadow: inset 0 0 0 darken($neutral-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: lighten(adjust-hue($neutral-medal, 10), 10%);
    text-shadow: 2px 2px 0 darken($neutral-medal, 20%);
    background: linear-gradient(to bottom right, $neutral-medal 50%, darken($neutral-medal, 5%) 50%);
  
    &.series-circle__circle--gold {
      box-shadow: inset 0 0 0 darken($gold-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
      border-color: lighten(adjust-hue($gold-medal, 10), 10%);
      text-shadow: 0 0 4px darken($gold-medal, 20%);
      background: linear-gradient(to bottom right, $gold-medal 50%, darken($gold-medal, 5%) 50%);
    }
  
    &.series-circle__circle--silver {
      box-shadow: inset 0 0 0 darken($silver-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
      border-color: lighten(adjust-hue($silver-medal, 10), 10%);
      text-shadow: 0px 0px 4px darken($silver-medal, 20%);
      background: linear-gradient(to bottom right, $silver-medal 50%, darken($silver-medal, 5%) 50%);
    }
  
    &.series-circle__circle--bronze {
      box-shadow: inset 0 0 0 darken($bronze-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
      border-color: lighten(adjust-hue($bronze-medal, 10), 10%);
      text-shadow: 0 0 4px darken($bronze-medal, 20%);
      background: linear-gradient(to bottom right, $bronze-medal 50%, darken($bronze-medal, 5%) 50%);
    }
  }



.star {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  animation: glitter 4.5s linear 0s infinite normal;
  -webkit-animation: glitter 4.5s linear 0s infinite normal;
  -moz-animation: glitter 4.5s linear 0s infinite normal;
  -ms-animation: glitter 4.5s linear 0s infinite normal;
  -o-animation: glitter 4.5s linear 0s infinite normal;
}

/*CSS3 keyframes for glittering effect*/

@-webkit-keyframes glitter {
  0% {
    -webkit-transform: scale(0.2);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(0.2);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
}
@-moz-keyframes glitter {
  0% {
    -moz-transform: scale(0.5);
    opacity: 1;
  }
  25% {
    -moz-transform: scale(0.2);
    opacity: 0;
  }
  50% {
    -moz-transform: scale(0.5);
    opacity: 1;
  }
  75% {
    -moz-transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -moz-transform: scale(0.5);
    opacity: 1;
  }
}
@keyframes glitter {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  25% {
    transform: scale(0.2);
    opacity: 0;
  }
  50% {
    transform: scale(0.5);
    opacity: 1;
  }
  75% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(0.5);
    opacity: 1;
  }
}